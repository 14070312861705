import Vue from 'vue'
import { find } from 'lodash-es'

export default Vue.extend({
  computed: {
    availableVariantTypes () {
      return this.$store.state.variantTypes.collection
    }
  },
  methods: {
    getVariantTypeLabel (key: string): string {
      const type = find(this.availableVariantTypes, { key })
      return type ? type.label : key
    }
  }
})
