<template>
    <div class="row">
      <div class="col-12">
        <div class="card mb-4">
          <div class="card-header">
            Comment remplir le fichier CSV d'importation de produits
          </div>
          <div class="card-body">
            <async-object
              should-fetch
              :fetch-method="() => $store.dispatch('pages/fetchCSVProductsDocumentation')"
            >
              <div class="markdown-table" v-html="compiledMarkdown" />
            </async-object>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import AsyncObject from '@/components/common/AsyncObject'
import sanitizeHtml from 'sanitize-html'
import marked from 'marked'

export default {
  components: {
    AsyncObject
  },
  computed: {
    content () {
      return this.$store.state.pages.csvProductsDocumentation.content
    },
    compiledMarkdown () {
      return this.content ? sanitizeHtml(marked(this.content, { breaks: true, gfm: true })) : ''
    }
  }
}
</script>

<style scoped>
</style>
