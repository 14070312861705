<template>
  <div class="option-form">
    <div v-if="!(initialOptions && initialOptions.length > 0)" class="mb-3">Configurez ici les options que vos clients devront sélectionner lors de l'achat. Par exemple la taille, la couleur, etc. Maximum 3 options.</div>

    <template v-else>
      <table class="table table-borderless">
        <thead>
        <tr>
          <th>Nom de l'option</th>
          <th>Type de champ</th>
          <th></th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="option in initialOptions" :key="option">
          <td>{{ getVariantTypeLabel(option) }}</td>
          <td>Texte libre</td>
          <td class="text-right">
            <button class="btn btn-sm btn-outline-danger" @click="removeOption(option)">Retirer</button>
          </td>
        </tr>
        </tbody>
      </table>
    </template>

    <v-popover
      offset="16"
      placement="right"
    >
      <button class="btn btn-outline-secondary">Ajouter une option</button>

      <template slot="popover">
        <div class="form-group">
          <select class="custom-select" v-model="form.selectedOption">
            <option value="">Sélectionner...</option>
            <option v-for="type in availableVariantTypes" :key="type.id" :value="type.key">{{ type.label }}</option>
            <option v-if="availableVariantTypes.length > 0" value="new_type">Autre...</option>
          </select>
        </div>

        <div class="form-group" v-if="form.selectedOption === 'new_type'">
          <input type="text" class="form-control" v-model="form.customOptionLabel">
        </div>

        <button
          class="btn btn-secondary btn-sm"
          v-close-popover
          :disabled="!canSubmit"
          @click="addOption"
        >Ajouter</button>
      </template>
    </v-popover>
  </div>
</template>

<script>
import userData from '@/mixins/user-data'
import useVariantTypes from '@/mixins/useVariantTypes'

// TODO: when an option has been added, remove it from selector
// TODO: limit to 3 options

export default {
  props: {
    initialOptions: {
      type: Array,
      default () {
        return []
      }
    }
  },
  mixins: [userData, useVariantTypes],
  data () {
    return {
      form: {
        selectedOption: '',
        customOptionLabel: ''
      },
      isProcessing: false
    }
  },
  computed: {
    canSubmit () {
      if (this.isProcessing) {
        return false
      }

      return (this.form.selectedOption && this.form.selectedOption !== 'new_type') || (this.form.selectedOption === 'new_type' && this.form.customOptionLabel)
    }
  },
  methods: {
    async addOption () {
      if (this.form.selectedOption === 'new_type') {
        if (!this.form.customOptionLabel) {
          return
        }

        this.isProcessing = true

        try {
          const type = await this.$store.dispatch('variantTypes/create', {
            label: this.form.customOptionLabel,
            storeId: this.currentStoreId
          })

          this.resetForm()
          this.emitChangeEvent(type.key)
        } catch {
          this.$toasted.error('Une erreur s\'est produite. Veuillez réessayer.')
          this.isProcessing = false
        }

        return
      }

      this.emitChangeEvent(this.form.selectedOption)
      this.resetForm()
    },
    emitChangeEvent (selectedValue) {
      this.$emit('change', [
        ...this.initialOptions,
        selectedValue
      ])
    },
    async removeOption (option) {
      try {
        await this.$modal.openConfirmModal({
          title: 'Supprimer cette option',
          message: 'Veuillez confirmer que vous voulez supprimer cette option.',
          confirmLabel: 'Confirmer',
          cancelLabel: 'Annuler'
        })

        this.$emit('change', [
          ...this.initialOptions.filter(value => value !== option)
        ])
      } catch (error) {
        if (error === 'ACTION_CANCELLED') {
          return
        }

        this.$toasted.error('Une erreur s\'est produite. Veuillez réessayer.')
      }
    },
    resetForm () {
      this.form = {
        selectedOption: '',
        customOptionLabel: ''
      }
    }
  },
  created () {
    this.$store.dispatch('variantTypes/fetchOrRefresh', this.currentStoreId)
  }
}
</script>

<style lang="scss">
.popover-inner {
  width: 300px;
  max-width: 300px;
  text-align: start;
}
</style>
