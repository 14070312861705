














import Vue from 'vue'
import { ChevronDownIcon } from 'vue-feather-icons'
import useVariantTypes from '@/mixins/useVariantTypes'

export default Vue.extend({
  name: 'ProductDropdown',
  mixins: [useVariantTypes],
  components: {
    ChevronDownIcon
  },
  watch: {
    options: {
      handler () {
        this.selected = null
      },
      deep: true,
      immediate: true
    }
  },
  props: {
    options: {
      type: Array,
      required: true
    },
    title: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      selected: null
    }
  },
  methods: {
    handleChange (option: any) {
      this.selected = option
      this.$emit('value-change', { [this.title]: option })
    }
  }
})
