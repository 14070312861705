<template>
  <div class="simple-product-editor">
    <div class="row">
      <div class="col-12">
        <div class="card mb-4">
          <div class="card-header">Informations générales</div>
          <div class="card-body">
            <general-form :initial-values="generalFormValues" @change="handleGeneralForm" :show-errors="showErrors"></general-form>
          </div>
        </div>

        <div class="card mb-4">
          <div class="card-header">Prix</div>
          <div class="card-body">
            <price-form :initial-values="priceFormValues" @change="handlePriceForm" :show-errors="showErrors"></price-form>
          </div>
        </div>

        <div class="card mb-4">
          <div class="card-header">Inventaire</div>
          <div class="card-body">
            <inventory-form :initial-values="inventoryFormValues" @change="handleInventoryValues" :show-errors="showErrors"></inventory-form>
          </div>
        </div>

        <div class="card mb-4">
          <div class="card-header">Dimensions de l'emballage</div>
          <div class="card-body">
            <package-form :initial-values="packageForm" @change="handlePackageValues" :show-errors="showErrors"></package-form>
          </div>
        </div>

        <div class="card mb-4">
          <div class="card-header">
            Image du produit
            <icon-tooltip icon="info-circle" tooltipText="Dimension recommandée 800 x 800px" placement="right" />
          </div>
          <div class="card-body">
            <product-images />
          </div>
        </div>

        <div class="mb-4">
          <save-product-button :edit-mode="editMode" :is-saving="isSaving" @handleSave="handleSave" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { get, omit, pick } from 'lodash-es'
import GeneralForm from '@/components/products/editors/GeneralForm'
import PriceForm from '@/components/products/editors/PriceForm'
import PackageForm from '@/components/products/editors/PackageForm'
import InventoryForm from '@/components/products/editors/InventoryForm'
import ProductImages from '@/components/products/editors/ProductImages'
import userData from '@/mixins/user-data'
import formError from '@/mixins/form-error'
import IconTooltip from '@/components/common/IconTooltip'
import SaveProductButton from '@/components/products/editors/SaveProductButton'

export default {
  mixins: [userData, formError],
  components: {
    IconTooltip,
    ProductImages,
    GeneralForm,
    PriceForm,
    PackageForm,
    InventoryForm,
    SaveProductButton
  },
  data () {
    return {
      isGeneralFormValid: null,
      isPriceFormValid: null,
      isInventoryFormValid: null,
      isPackageFormValid: null,
      showErrors: false,
      isReady: false,
      isSaving: false
    }
  },
  computed: {
    editMode () {
      return !!this.$route.params.id
    },
    generalFormValues () {
      return pick(this.$store.state.productEditor, ['name', 'subtitle', 'shortDescription', 'description', 'categoryId'])
    },
    priceFormValues () {
      return {
        price: get(this.$store.state.productEditor, 'defaultPrice'),
        regularPrice: get(this.$store.state.productEditor, 'defaultRegularPrice'),
        taxes: get(this.$store.state.productEditor, 'taxes')
      }
    },
    inventoryFormValues () {
      const storeInventoryMode = get(this.$store.state.productEditor, 'variants[0].inventoryMode')
      const storeInventoryStatus = get(this.$store.state.productEditor, 'variants[0].inventoryStatus')

      return {
        sku: get(this.$store.state.productEditor, 'variants[0].sku'),
        limitPerOrder: get(this.$store.state.productEditor, 'variants[0].limitPerOrder'),
        finalSale: get(this.$store.state.productEditor, 'variants[0].finalSale'),
        stockInventoryMode: storeInventoryMode === 'stock',
        statusInventoryMode: storeInventoryMode === 'status' && storeInventoryStatus === 'in_stock',
        inventoryQuantity: get(this.$store.state.productEditor, 'variants[0].inventoryQuantity'),
        allowBackorders: get(this.$store.state.productEditor, 'variants[0].allowBackorders'),
        lowStockThreshold: get(this.$store.state.productEditor, 'variants[0].lowStockThreshold')
      }
    },
    packageForm () {
      return {
        length: get(this.$store.state.productEditor, 'variants[0].length'),
        width: get(this.$store.state.productEditor, 'variants[0].width'),
        height: get(this.$store.state.productEditor, 'variants[0].height'),
        weight: get(this.$store.state.productEditor, 'variants[0].weight')
      }
    }
  },
  methods: {
    formStatusChanged (state) {
      if (this.isReady) {
        this.$emit('formStatusChanged', state)
      }
    },
    handleGeneralForm (values, isValid) {
      this.formStatusChanged(JSON.stringify(this.generalFormValues) !== JSON.stringify(values))
      this.$store.commit('productEditor/SET_VALUES', values)
      this.isGeneralFormValid = isValid
    },
    handlePriceForm (values, isValid) {
      this.formStatusChanged(JSON.stringify(this.priceFormValues) !== JSON.stringify(values))
      this.$store.commit('productEditor/SET_VALUES', {
        defaultPrice: Number(values.price),
        defaultRegularPrice: Number(values.regularPrice),
        taxes: values.taxes
      })

      this.isPriceFormValid = isValid
    },
    handleInventoryValues (values, isValid) {
      this.formStatusChanged(JSON.stringify(this.inventoryFormValues) !== JSON.stringify(values))
      this.$store.commit('productEditor/SET_UNIQUE_VARIANT_VALUES', this.getInventoryData(values))
      this.isInventoryFormValid = isValid
    },
    getInventoryData (values) {
      const inventoryMode = values.stockInventoryMode ? 'stock' : 'status'
      const inventoryQuantity = values.stockInventoryMode ? values.inventoryQuantity : null
      let inventoryStatus = !!inventoryQuantity && values.stockInventoryMode ? 'in_stock' : 'out_of_stock'

      if (!values.stockInventoryMode && values.statusInventoryMode) {
        inventoryStatus = values.statusInventoryMode ? 'in_stock' : 'out_of_stock'
      }

      return {
        ...omit(values, ['stockInventoryMode', 'statusInventoryMode']),
        inventoryMode,
        inventoryStatus,
        inventoryQuantity
      }
    },
    handlePackageValues (values, isValid) {
      this.formStatusChanged(JSON.stringify(this.packageForm) !== JSON.stringify(values))
      this.$store.commit('productEditor/SET_UNIQUE_VARIANT_VALUES', values)
      this.isPackageFormValid = isValid
    },
    async handleSave (status) {
      this.showErrors = true

      if (!this.isGeneralFormValid || !this.isPriceFormValid || !this.isInventoryFormValid || !this.isPackageFormValid) {
        this.toastFormError()
        return
      }

      if (status) {
        this.$store.commit('productEditor/SET_STATUS', status)
      }

      this.isSaving = true
      await this.$store.dispatch('productEditor/save', {
        storeId: this.currentStoreId
      })

      this.$emit('formStatusChanged', false)
      this.isSaving = false

      if (!this.editMode) {
        this.$router.push('/products')
      }
    }
  },
  async created () {
    await this.$store.dispatch('taxRates/fetchOrRefresh', this.currentStoreId)
    this.isReady = true
  }
}
</script>
