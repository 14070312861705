<template>
  <app-layout>
    <div class="px-4 p-sm-5 container-fluid">
      <div class="heading d-flex justify-content-between flex-column flex-sm-row mb-5">
        <h2 class="mb-4 mb-sm-0">Produits</h2>

        <div class="w-100 d-flex align-items-center justify-content-end flex-wrap-reverse">
          <button class="btn btn-outline-primary mr-2" @click="$router.push({ name: 'products.import' })" :disabled="isSuspended">Import CSV</button>

          <b-dropdown id="dropdown-create-product" variant="primary" text="Ajouter un produit" :disabled="isSuspended">
            <b-dropdown-item @click="$router.push({ name: 'products.create', params: { type: 'simple' } })">Produit simple</b-dropdown-item>
            <b-dropdown-item @click="$router.push({ name: 'products.create', params: { type: 'variable' } })">Produit avec variantes</b-dropdown-item>
          </b-dropdown>
        </div>
      </div>

      <template v-if="isLoading">
        <b-spinner></b-spinner>
      </template>

      <template v-else>
        <pagination-filter
          state="products"
          placeholder-text-filter="Chercher un produit"
          :display-text-filter="true"
          :display-type-filter="true"
          :list-type-filter="getTypeList"
          :display-status-filter="true"
          :list-status-filter="getStatusList"
          :display-reset="true"
          @changePage="changePage"
        />

        <div class="card mb-4">
          <div class="card-body p-0">
            <collection-table
              :cols="cols"
              :list-items="products"
              :sort="sortBy"
              :sort-direction="sortDirection"
              :only-display-sort-col="true"
              :is-fetching="isFetching"
              :default-actions="false"
              :custom-actions="getActions"
              no-top-border
              hover
              :link="(item) => `/products/edit/${item.id}`"
              @edit="(item) => $router.push({ name: 'products.edit', params: { id: item.id } })"
              @delete="handleDelete"
              @draft="eventDraft"
              @published="eventPublished"
              @archived="eventArchived"
              :empty-img-src="require('@/assets/images/icons/product-2.svg')"
              empty-message="Vos produits s'afficheront ici"
              @sortBy="handleSortBy"
            ></collection-table>

            <div v-if="paginatedData.data.length > 0" class="drawer-footer">
              <pagination state="products"
                          :current-page="page"
                          :pages="paginatedData.pageCount"
                          :display-per-page="true"
                          :range="2"
                          @change="changePage"
              />
            </div>
          </div>
        </div>
      </template>
    </div>
  </app-layout>
</template>

<script>
import userData from '@/mixins/user-data'
import formatsCurrency from '@/mixins/formatsCurrency'
import Pagination from '@/components/common/Pagination'
import PaginationFilter from '@/components/common/PaginationFilter'
import Thumbnail from '@/components/common/Thumbnail'
import { getStorageFileSource } from '@/utils/files'
import pagination from '@/mixins/pagination'

export default {
  components: {
    Pagination,
    PaginationFilter
  },
  mixins: [userData, formatsCurrency, pagination],
  computed: {
    getStatusList () {
      return [
        {
          value: 'draft',
          label: 'Brouillon'
        },
        {
          value: 'published',
          label: 'Publiés'
        },
        {
          value: 'archived',
          label: 'Archivés'
        }
      ]
    },
    getTypeList () {
      return [
        {
          value: 'simple',
          label: 'Produits simples'
        },
        {
          value: 'variable',
          label: 'Produits avec variantes'
        }
      ]
    },
    cols () {
      return [
        {
          header: 'Aperçu',
          property: 'productPhotos',
          component: Thumbnail,
          props: (item) => {
            const photo = item.productPhotos?.find(photo => photo.isGlobal)
            return {
              src: photo?.file ? getStorageFileSource(photo.file) : undefined,
              alt: item.name
            }
          }
        },
        {
          header: 'Nom du produit',
          property: 'name',
          isSortable: true
        },
        {
          header: 'Prix par défaut',
          property: 'defaultPrice',
          method: (product) => {
            return !product.defaultPrice
              ? this.$money(product.defaultRegularPrice)
              : this.$money(product.defaultPrice)
          },
          isSortable: true
        },
        {
          header: 'Type',
          property: 'type',
          method: (product) => {
            return product.type === 'simple' ? 'Simple' : 'Variantes'
          },
          isSortable: true
        },
        {
          header: 'Variantes',
          property: 'variants',
          method: (product) => {
            if (product.type === 'simple') {
              return '-'
            }

            return product.variants.length
          }
        },
        {
          header: 'Statut',
          property: 'status',
          method: (product) => {
            switch (product.status) {
              case 'published':
                return 'Publié'
              case 'archived':
                return 'Archivé'
              default:
                return 'Brouillon'
            }
          },
          isSortable: true
        }
      ]
    },
    products () {
      return this.paginatedData.data
    },
    isSuspended () {
      return this.$store.getters.isSuspended
    }
  },
  methods: {
    getActions (item) {
      const actions = []

      const draft = {
        label: 'Brouillon',
        icon: '',
        event: 'draft',
        index: 10
      }

      const published = {
        label: 'Publier',
        icon: '',
        event: 'published',
        index: 10
      }

      const archived = {
        label: 'Archiver',
        icon: '',
        event: 'archived',
        index: 10
      }

      switch (item.status) {
        case 'draft':
          actions.push(published)
          break
        case 'published':
          actions.push(draft)
          actions.push(archived)
          break
        case 'archived':
          actions.push(draft)
          break
      }

      const defaultActions = [
        {
          label: 'Editer',
          icon: '',
          event: 'edit',
          index: 10
        },
        {
          label: 'Supprimer',
          icon: '',
          event: 'delete',
          index: 10
        }
      ]

      return [...actions, ...defaultActions]
    },
    async updateStatus (id, status) {
      await this.$store.dispatch('products/updateStatus', { id, status, storeId: this.currentStoreId })
    },
    async eventDraft (item) {
      await this.updateStatus(item.id, 'draft')
    },
    async eventPublished (item) {
      await this.updateStatus(item.id, 'published')
    },
    async eventArchived (item) {
      await this.updateStatus(item.id, 'archived')
    },
    async handleDelete (item) {
      try {
        await this.$modal.openConfirmModal({
          title: 'Supprimer le produit',
          message: 'Veuillez confirmer que vous voulez supprimer ce produit.',
          confirmLabel: 'Confirmer',
          cancelLabel: 'Annuler'
        })

        this.$store.dispatch('products/delete', { storeId: this.currentStoreId, id: item.id })
      } catch (error) {
        if (error === 'ACTION_CANCELLED') {
          return
        }

        this.$toasted.error('Une erreur s\'est produite. Veuillez réessayer.')
      }
    },
    async changePage (page) {
      await this.fetchProducts(page)
    },
    async fetchProducts (page) {
      await this.$store.dispatch('products/fetch', { page, storeId: this.currentStoreId })
    },
    async handleSortBy (evt) {
      this.sortBy = evt.property
      this.sortDirection = evt.direction

      await this.fetchProducts()
    }
  },
  async created () {
    this.instance = 'products'
    await this.fetchProducts()
  }
}
</script>

<style lang="scss" scoped>
.container-fluid >>> .variants-col-width {
  max-width: 60px
}
</style>
