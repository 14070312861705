import Vue from 'vue'
import http from '@/utils/http'
import { orderBy } from 'lodash-es'
import { ProductPhoto } from '@/store/types/products'
import { File as ProductImageFile } from '@/store/types/files'
import { getStorageFileSource } from '@/utils/files'

const FILE_TYPE = 'productImage'

export default Vue.extend({
  data () {
    return {
      isUploading: false
    }
  },
  computed: {
    productId (): string {
      return this.$store.state.productEditor.id
    },
    productPhotos: {
      get (): ProductPhoto[] {
        return orderBy(this.$store.state.productEditor.productPhotos, ['rank'])
      },
      set (photos: ProductPhoto[]) {
        const rankedProductPhotos = photos.map((photo, index) => ({ ...photo, rank: index + 1 }))
        this.$store.commit('productEditor/UPDATE_PRODUCT_PHOTOS', rankedProductPhotos)
      }
    }
  },
  methods: {
    async handleUpload (files: File[], shouldUpdate = false) {
      try {
        this.isUploading = true
        const storeId = this.$store.state.auth.currentStoreId
        const productImages = await Promise.all(Array.from(files).map(async (file, index) => {
          // data to be used in order to send file to server
          const formData = new FormData()
          formData.append('file', file)
          formData.append('type', FILE_TYPE)

          const { data: productFile } = await http.post(`/v1/stores/${storeId}/file`,
            formData,
            { headers: { 'Content-Type': 'multipart/form-data' } }
          )

          return this.getVirginPhoto(productFile, storeId, index)
        }))

        if (shouldUpdate) {
          this.$store.commit('productEditor/UPDATE_PRODUCT_PHOTOS', [...this.productPhotos, ...productImages])
        }

        this.isUploading = false
        return productImages
      } catch {
        this.$toasted.error("Une erreur s'est produite. Veuillez réessayer.")
      }
    },
    getVirginPhoto (file: ProductImageFile, storeId: string, index: number): ProductPhoto {
      return {
        id: null,
        storeId,
        productId: this.productId,
        isGlobal: true,
        fileId: file.id,
        file,
        rank: !this.productPhotos.length ? index + 1 : Math.max(...this.productPhotos.map((image) => image.rank || 0)) + index + 1,
        productVariantPhotos: null
      }
    },
    getUrl (file: ProductImageFile | undefined): string {
      if (!file) {
        return ''
      }

      return getStorageFileSource(file)
    }
  }
})
