<template>
  <div>
    <div class="alert alert-danger" v-if="hasError">
      <template v-if="!!serverErrorMessage.length">
        Une ou plusieurs images n'existent pas ou peuvent ne pas respecter les exigences d'image appropriées. <br />
        Veuillez consulter notre <span class="cursor-pointer" @click="$emit('switch')"><u>documentation</u></span> pour corriger ces erreurs et réessayer.
      </template>
      <template v-else>
        Des erreurs ont été trouvées dans le fichier CSV. <br />
        Veuillez consulter notre <span class="cursor-pointer" @click="$emit('switch')"><u>documentation</u></span> pour corriger ces erreurs et réessayer.
      </template>
    </div>

    <div class="row">
      <div class="col-12">
        <div class="card mb-4">
          <div class="card-header d-flex justify-content-between align-items-baseline">
            <div class="d-flex align-items-baseline">
              Ajouter des produits en masse à l'aide d'un fichier CSV
              <button class="btn btn-link btn-sm text-uppercase text-black-50" @click="$emit('switch')">
                <span class="small">En savoir plus</span>
              </button>
            </div>
            <div>
              <button class="btn btn-link btn-sm px-0" @click="handleCSVDownload">Télécharger le modèle CSV</button>
            </div>
          </div>
          <div class="card-body">
            <file-upload-button
              v-if="!file"
              :disabled="isUploading"
              :loading="isUploading"
              accept=".csv"
              @click="handleUpload"
              text="Déposez votre CSV"
            />
            <div v-else>
              <div class="text-center p-2 mt-2">
                <h6>{{ file.name }} <span class="small ml-2 p-1 border rounded">{{ fileSize }} KB</span></h6>
              </div>
              <div class="d-flex justify-content-between">
                <file-upload-button :disabled="isSaving" variant="btn" size="sm" class="px-0" accept=".csv" @click="handleUpload">
                  Remplacer
                </file-upload-button>
                <button :disabled="isSaving" class="btn btn-link text-danger btn-sm px-0" @click="handleDelete">Enlever</button>
              </div>
            </div>
          </div>
        </div>

        <div class="row" v-if="file">
          <div class="col-12">
            <div class="card mb-4">
              <div class="card-body p-0">
                <collection-table
                  :cols="cols"
                  :list-items="products"
                  no-top-border
                  hover
                  :show-actions="false"
                  :errors="errors"
                  :warnings="warnings"
                  error-identifier="externalId"
                  :get-error-message="getErrorMessage"
                  :get-warning-message="getWarningMessage"
                ></collection-table>
              </div>
            </div>
          </div>
        </div>

        <div class="mb-4" v-if="file">
          <button-loader :disabled="isSaving || (errors && !!Object.values(errors).length)" :loading="isSaving" @click="handleSave">
            Démarrer l'import
          </button-loader>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FileUploadButton from '@/components/common/FileUploadButton'
import ButtonLoader from '@/components/common/ButtonLoader'
import promptUnsaved from '@/mixins/promptUnsaved'
import userData from '@/mixins/user-data'
import http from '@/utils/http'
import { initiateFileDownload } from '@/utils/files'
import formatsCurrency from '@/mixins/formatsCurrency'

const BAD_IMAGE_STATUS = 'bad_images'

export default {
  mixins: [promptUnsaved, userData, formatsCurrency],
  components: {
    FileUploadButton,
    ButtonLoader
  },
  data () {
    return {
      isUploading: false,
      isSaving: false,
      hasError: false,
      serverErrorMessage: '',
      file: null,
      products: [],
      errors: null,
      warnings: null
    }
  },
  computed: {
    cols () {
      return [
        {
          header: 'Vos nouveaux produits',
          property: 'name'
        },
        {
          header: 'Prix par défaut',
          property: 'defaultPrice',
          method: (product) => {
            return !product.defaultPrice
              ? this.$money(product.defaultRegularPrice)
              : this.$money(product.defaultPrice)
          }
        },
        {
          header: 'Type',
          property: 'type',
          method: (product) => {
            return product.type === 'simple' ? 'Simple' : 'Variantes'
          }
        },
        {
          header: 'Variantes',
          property: 'variants',
          headerClass: 'variants-col-width',
          method: (product) => {
            if (product.type === 'simple') {
              return '-'
            }

            return product.variants.length
          }
        }
      ]
    },
    fileSize () {
      if (!this.file) {
        return
      }

      return (this.file.size / 1024).toFixed(2) // in kb
    }
  },
  methods: {
    async handleCSVDownload () {
      try {
        const response = await http.get(`/v1/stores/${this.currentStoreId}/products-csv/download`)

        initiateFileDownload(response.data, response.headers['content-type'], 'le_bon_panier_template_produits')
      } catch {
        this.hasError = true
      }
    },
    async handleUpload (files) {
      try {
        this.products = []
        this.hasError = false
        this.serverErrorMessage = ''
        this.isUploading = true
        this.hasPendingChanges = true
        this.file = null

        const [file] = files
        // data to be used in order to send file to server
        const formData = new FormData()
        formData.append('file', file)

        const { data } = await http.post(`/v1/stores/${this.currentStoreId}/products-csv`,
          formData,
          { headers: { 'Content-Type': 'multipart/form-data' } }
        )

        this.products = data.products
        this.errors = data.errors
        this.warnings = data.warnings
        this.file = file
        this.hasError = data.errors && Object.values(data.errors).length > 0
      } catch (e) {
        this.hasError = true
        if (e?.response?.status === 400 && e?.response?.data === BAD_IMAGE_STATUS) {
          this.serverErrorMessage = BAD_IMAGE_STATUS
        }
      } finally {
        this.isUploading = false
      }
    },
    handleDelete () {
      this.hasPendingChanges = false
      this.file = null
    },
    getErrorMessage (properties) {
      return Object.keys(properties).reduce((messages, key) => {
        switch (key) {
          case 'name':
            return [...messages, 'Le titre est manquant']
          case 'description':
            return [...messages, 'La description est manquante']
          case 'categoryId':
            return [...messages, 'Le id de la catégorie est manquant ou ne correspond à aucune valeur de la liste de catégories']
          case 'defaultPrice':
            return [...messages, "Le prix est manquant ou n'a pas le bon format"]
          case 'defaultRegularPrice':
            return [...messages, "Le prix par défaut est manquant ou n'a pas le bon format"]
          case 'sku':
            return [...messages, "Le SKU n'est pas unique"]
          case 'taxes':
            return [...messages, 'Un ou plusieurs codes de taxes sont invalides']
          default:
            return [...messages, `Le champ "${key}" n'est pas défini ou n'a pas le bon format`]
        }
      }, [])
    },
    getWarningMessage (properties) {
      return Object.entries(properties).reduce((messages, entries) => {
        const [key, value] = entries

        switch (key) {
          case 'options':
            return [...messages, `Une ou plusieurs nouvelles options seront ajoutées (${value})`]
          case 'product':
            return [...messages, 'Ce produit existe déjà et peut être modifié lors de la synchronisation']
          default:
            return [...messages]
        }
      }, [])
    },
    async handleSave () {
      try {
        this.hasError = false
        this.serverErrorMessage = ''
        this.isSaving = true
        let shouldOverwrite = false

        const hasExistingProducts = Object.values(this.warnings).filter(warning => {
          return Object.keys(warning).includes('product')
        })

        if (hasExistingProducts.length > 0) {
          shouldOverwrite = await this.$modal.open(
            () => import('@/modals/ConfirmCSVImport.vue')
          )
        }

        if (!shouldOverwrite && hasExistingProducts.length === this.products.length) {
          this.$toasted.info("Aucun produit n'a été modifié.")
          return
        }

        // data to be used in order to send file to server
        const formData = new FormData()
        formData.append('file', this.file)
        formData.append('shouldOverwrite', shouldOverwrite.toString())

        await http.post(`/v1/stores/${this.currentStoreId}/products-csv/create`,
          formData,
          { headers: { 'Content-Type': 'multipart/form-data' } }
        )

        this.products = []
        this.hasPendingChanges = false
        this.$toasted.success('Produits enregistrés avec succès.')
        this.$router.push('/products')
      } catch (e) {
        if (e === 'ACTION_CANCELLED') {
          return
        }

        this.hasError = true
        let errorToastMessage = 'Des erreurs ont été trouvées dans le fichier CSV lors de la sauvegarde.'

        if (e?.response?.status === 400 && e?.response?.data === BAD_IMAGE_STATUS) {
          this.serverErrorMessage = BAD_IMAGE_STATUS
          errorToastMessage = "Une ou plusieurs images n'ont peut être pas été sauvegardées correctement. Veuillez vérifier dans vos nouveaux produits."
        }

        this.$toasted.error(errorToastMessage)
      } finally {
        this.isSaving = false
      }
    }
  }
}
</script>

<style scoped>
/deep/ .variants-col-width {
  max-width: 60px
}
</style>
