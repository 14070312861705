<template>
  <app-layout>
    <div class="px-4 px-sm-5 container">
      <div class="heading d-flex justify-content-between mb-5">
        <h2 v-if="editMode">Editer le produit</h2>
        <h2 v-else>Nouveau produit</h2>
      </div>

      <div class="row">
        <div class="col-12">
          <div class="mb-4">
            <router-link class="btn btn-link btn-sm" to="/products" active-class="active">
              <fa-icon :icon="['fas', 'chevron-left']" size="sm"></fa-icon>&nbsp;Retour
            </router-link>
          </div>
        </div>
      </div>

      <alert v-if="shouldConfigureTaxes" type="warning">
        Aucune configuration de taxes n'a été définie pour votre boutique, veuillez spécifier si votre entreprise est assujettie
        <router-link class="link-warning" to="/settings/taxes">aux taxes de vente</router-link> avant de créer un nouveau produit.
      </alert>
      <alert v-if="isSuspended" type="danger">
        Ce produit a été suspendu. Merci de contacter le support pour plus d'information.
      </alert>

      <async-object
        :should-fetch="editMode"
        :fetch-method="fetchProduct"
      >
        <b-tabs content-class="w-100">
          <b-tab title="Produit" active class="mt-3 py-4">
            <template v-if="isReady && productType === 'simple'">
              <simple-product-editor @formStatusChanged="formStatusChanged"></simple-product-editor>
            </template>

            <template v-if="isReady && productType === 'variable'">
              <variable-product-editor @formStatusChanged="formStatusChanged"></variable-product-editor>
            </template>
          </b-tab>
          <b-tab title="Aperçu">
            <preview :product="productPreview" />
          </b-tab>
        </b-tabs>
      </async-object>
    </div>
  </app-layout>
</template>

<script>
import AsyncObject from '@/components/common/AsyncObject'
import Alert from '@/components/common/Alert'
import SimpleProductEditor from '@/components/products/SimpleProductEditor'
import VariableProductEditor from '@/components/products/VariableProductEditor'
import promptUnsaved from '@/mixins/promptUnsaved'
import userData from '@/mixins/user-data'
import Preview from './Preview.vue'
import http from '@/utils/http'

export default {
  components: {
    AsyncObject,
    SimpleProductEditor,
    VariableProductEditor,
    Preview,
    Alert
  },
  mixins: [promptUnsaved, userData],
  data () {
    return {
      shouldConfigureTaxes: false
    }
  },
  computed: {
    productType () {
      return this.$store.state.productEditor.productType
    },
    editMode () {
      return !!this.$route.params.id
    },
    isReady () {
      return this.$store.state.productEditor.isReady
    },
    productPreview () {
      const storeName = this.currentStore ? this.currentStore.name : 'Ma boutique'

      return {
        ...this.$store.state.productEditor,
        storeName
      }
    },
    isSuspended () {
      return this.$store.state.productEditor.isSuspended
    }
  },
  methods: {
    fetchProduct () {
      return this.$store.dispatch('productEditor/initEditForm', this.$route.params.id)
    }
  },
  async created () {
    // check if user has ever configured taxes
    const { data } = await http.get(`/v1/stores/${this.currentStoreId}/settings`)
    this.shouldConfigureTaxes = !data || data.isTaxpayer === undefined

    if (!this.editMode) {
      this.$store.dispatch('productEditor/initCreateForm', this.$route.params.type)
    }
  },
  watch: {
    '$store.getters.isSuspended': {
      handler (value) {
        if (value && !this.editMode) {
          this.$router.push('/')
        }
      },
      deep: true
    }
  }
}
</script>

<style lang="scss" scoped>
.link-warning {
  color: inherit;
  text-decoration: underline;
  font-weight: 600;
}
</style>
