<template>
  <div class="general-form">
    <v-dynamic-form
      :fields="fields"
      :initial-values="initialValues"
      @change="handleChange"
      :errors="formErrors"
    ></v-dynamic-form>
  </div>
</template>

<script>
import handleForm from '@/mixins/handle-form'

export default {
  mixins: [handleForm],
  computed: {
    fields () {
      return [
        {
          name: 'name',
          label: 'Nom du produit',
          type: 'text',
          required: true,
          props: {
            maxlength: '100'
          }
        },
        {
          name: 'subtitle',
          label: 'Sous-titre',
          labelSecondary: 'Optionnel',
          props: {
            maxlength: '100'
          }
        },
        {
          name: 'shortDescription',
          label: 'Description courte',
          labelSecondary: 'Optionnel',
          props: {
            maxlength: '200'
          }
        },
        {
          name: 'description',
          label: 'Description',
          type: 'html-editor',
          required: true
        },
        {
          name: 'categoryId',
          label: 'Catégorie',
          type: 'product-category-selector',
          required: true
        }
      ]
    },
    validationRules () {
      return {
        name: 'required|max:100',
        subtitle: 'max:100',
        shortDescription: 'max: 200',
        description: 'required',
        categoryId: 'required'
      }
    }
  }
}
</script>
