<template>
  <div>
    <v-dynamic-form
      :fields="fields"
      :initial-values="initialValues"
      @change="handleChange"
      :errors="formErrors"
    ></v-dynamic-form>
  </div>
</template>

<script>
import handleForm from '@/mixins/handle-form'

export default {
  mixins: [handleForm],
  computed: {
    fields () {
      return [
        {
          name: 'sku',
          label: 'Référence (SKU)',
          helpText: 'Sera générée automatiquement si laissé vide'
        },
        {
          name: 'limitPerOrder',
          label: 'Limite par commande',
          labelSecondary: 'Optionnel',
          type: 'number'
        },
        {
          name: 'finalSale',
          label: 'Le produit est en vente finale',
          type: 'switch'
        },
        {
          name: 'stockInventoryMode',
          label: 'Gérer les quantités en stock',
          type: 'switch'
        },
        {
          name: 'statusInventoryMode',
          label: 'Le produit est en stock',
          type: 'switch',
          isVisible: this.shouldDisplayStockFields
        },
        {
          name: 'inventoryQuantity',
          label: 'Quantité en stock',
          type: 'number',
          helpText: 'Sera automatiquement recalculée lors des commandes et retours.',
          isVisible: this.shouldDisplayQuantityFields,
          required: true
        }
        // TODO add back allowing out of stock purchases + inform for low in stock (after MVP)
        /*
        {
          name: 'allowBackorders',
          label: 'Autoriser les commandes si le produit est en rupture de stock',
          type: 'checkbox',
          isVisible: this.shouldDisplayQuantityFields
        },
        {
          name: 'lowStockThreshold',
          label: "M'aviser si l'inventaire est inférieur à :",
          type: 'number',
          isVisible: this.shouldDisplayQuantityFields
        }
        */
      ]
    },
    validationRules () {
      return {
        inventoryQuantity: 'required_if:stockInventoryMode,true|min:0'
      }
    }
  },
  methods: {
    shouldDisplayQuantityFields (values) {
      return values.stockInventoryMode
    },
    shouldDisplayStockFields (values) {
      return !values.stockInventoryMode
    }
  }
}
</script>
