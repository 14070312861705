<template>
  <div>
    <v-dynamic-form
      :fields="fields"
      :initial-values="initialValues"
      @change="handleChange"
      :errors="formErrors"
    ></v-dynamic-form>
  </div>
</template>

<script>
import handleForm from '@/mixins/handle-form'
import useVariantTypes from '@/mixins/useVariantTypes'

export default {
  mixins: [handleForm, useVariantTypes],
  props: {
    options: {
      type: Array,
      default () {
        return []
      }
    }
  },
  computed: {
    fields () {
      const items = []

      for (const option of this.options) {
        items.push({
          name: option,
          label: this.getVariantTypeLabel(option),
          required: true
        })
      }

      return items
    },
    validationRules () {
      const rules = {}

      for (const option of this.options) {
        rules[option] = 'required'
      }

      return rules
    }
  }
}
</script>

<style scoped>
</style>
