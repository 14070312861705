<template>
  <app-layout>
    <div class="px-4 px-sm-5 container container-left">
      <div class="heading d-flex justify-content-between mb-5">
        <h2>Nouvelle variante</h2>
      </div>

      <button type="button" class="btn btn-primary btn-sm" @click="open = true">Ajouter une variante</button>
      <variant-drawer :open="open" @cancel="open = false"></variant-drawer>
    </div>
  </app-layout>
</template>

<script>

import VariantDrawer from '@/components/products/editors/variable-products/VariantDrawer'
export default {
  components: {
    VariantDrawer
  },
  data () {
    return {
      open: false
    }
  }
}
</script>

<style scoped>
</style>
