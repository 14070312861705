<template>
  <div :class="[sizeClass, {'border-danger': isAboveMax || isBellowMin}]" class="pro-qty">
    <span class="dec qtybtn" :class="{'not-allowed': isBellowMin || isEqualToMin}" @click.prevent="handleDecrement()">-</span>
    <input type="text" :value="value" @input="handleChange">
    <span class="inc qtybtn" :class="{'not-allowed': isAboveMax || isEqualToMax}" @click.prevent="handleIncrement()">+</span>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Number,
      default: 1
    },
    min: {
      type: [Number, String],
      default: 1
    },
    max: {
      type: [Number, String],
      default: 1000
    },
    step: {
      type: Number,
      default: 1
    },
    size: {
      type: String,
      required: false,
      default: null
    }
  },
  computed: {
    sizeClass () {
      const acceptedSizes = ['sm']
      return this.size && acceptedSizes.includes(this.size) ? `quantity-${this.size}` : null
    },
    isBellowMin () {
      return this.value < this.min
    },
    isAboveMax () {
      return this.max && this.value > this.max
    },
    isEqualToMin () {
      return this.value === this.min
    },
    isEqualToMax () {
      return this.max && this.value === this.max
    }
  },
  methods: {
    handleDecrement () {
      const newValue = this.value - this.step

      if (newValue < this.min) {
        return
      }

      this.$emit('value-change', Number(newValue))
    },
    handleIncrement () {
      const newValue = this.value + this.step

      if (this.max && newValue > this.max) {
        return
      }

      this.$emit('value-change', Number(newValue))
    },
    handleChange (event) {
      const value = typeof event === 'object' && event.target ? event.target.value : event
      this.$emit('value-change', Number(value))
    }
  }
}
</script>

<style lang="scss" scoped>
.pro-qty {
  font-size: 0.875rem;
  height: 50px;
  width: 150px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #ebebeb;
  padding: 0 20px;
  overflow: hidden;

  .qtybtn {
    color: #666;
    cursor: pointer;
    float: left;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  input {
    width: 20px;
    color: #666;
    font-weight: bold;
    border: none;
    float: left;
    text-align: center;
    box-shadow: none;
    outline: none;
  }
  .not-allowed {
    cursor: not-allowed;
  }
}
.quantity-sm {
  width: 92px;
  height: 30px;
  font-size: 0.75rem;
  line-height: 0.75rem;

  input {
    width: 36px;
  }
}
</style>
