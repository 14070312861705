<template>
  <div class="variant-price-form">
    <div class="form-group">
      <b-form-checkbox
        :checked="pricePolicy === 'custom_price'"
        @change="togglePricePolicy"
        switch
      >
        Définir un prix spécifique
      </b-form-checkbox>
    </div>

    <template v-if="pricePolicy === 'default_price'">
      <label for="default_price">Prix de vente par défaut</label>
      <input
        type="text"
        class="form-control w-100"
        disabled
        id="default_price"
        :value="productDefaultPrice"
      >
    </template>
    <template v-else>
      <price-form :initial-values="initialValues" @change="handlePriceValues" :show-errors="showErrors" :show-taxes="false"></price-form>
    </template>
  </div>
</template>

<script>
import { get } from 'lodash-es'
import handleForm from '@/mixins/handle-form'
import PriceForm from '@/components/products/editors/PriceForm'

export default {
  mixins: [handleForm],
  components: {
    PriceForm
  },
  data () {
    return {
      isValid: true
    }
  },
  props: {
    productDefaultPrice: {
      type: Number,
      required: true
    }
  },
  computed: {
    pricePolicy () {
      return get(this.initialValues, 'pricePolicy')
    }
  },
  methods: {
    handlePriceValues (values, isValid) {
      this.isValid = isValid

      this.$emit('change', {
        ...this.initialValues,
        ...values
      }, isValid)
    },
    togglePricePolicy () {
      const newPricePolicy = this.pricePolicy === 'default_price' ? 'custom_price' : 'default_price'

      if (newPricePolicy === 'default_price') {
        this.isValid = true
      }

      this.$emit('change', {
        ...this.initialValues,
        pricePolicy: newPricePolicy
      }, this.isValid)
    }
  }
}
</script>

<style scoped>
</style>
