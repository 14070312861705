<template>
  <div class="alert" :class="{ 'alert-danger': type === 'danger', 'alert-warning': type === 'warning', 'alert-info': type === 'info', 'alert-success': type === 'success' }">
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String
    }
  }
}
</script>

<style scoped>
</style>
