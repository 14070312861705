

































































































import Vue from 'vue'

export default Vue.extend({
  props: {
    description: {
      type: String,
      default: ''
    },
    specifications: {
      type: Object,
      required: false,
      default: () => {
        return {}
      }
    }
  }
})
