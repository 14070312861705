<template>
  <div class="simple-product-editor">
    <div class="row">
      <div class="col-12">
        <div class="card mb-4">
          <div class="card-header">Informations générales</div>
          <div class="card-body">
            <general-form :initial-values="generalFormValues" @change="handleGeneralForm" :show-errors="showErrors"></general-form>
          </div>
        </div>

        <div class="card mb-4">
          <div class="card-header">Prix par défaut</div>
          <div class="card-body">
            <p class="text-muted">Vous pourrez au besoin déterminer un prix particulier pour chaque déclinaison.</p>
            <price-form :initial-values="priceFormValues" @change="handlePriceForm" :show-errors="showErrors"></price-form>
          </div>
        </div>

        <div class="card mb-4">
          <div class="card-header">Options</div>
          <div class="card-body">
            <option-form :initial-options="optionsValues" @change="handleOptions"></option-form>
          </div>
        </div>

        <div class="card mb-4">
          <div class="card-header">Variantes</div>
          <div class="card-body">
            <variant-table :variants="variants" :options="optionsValues" @add="createVariant" @edit="editVariant"></variant-table>
          </div>
        </div>

        <div class="card mb-4">
          <div class="card-header">
            Image du produit
            <icon-tooltip icon="info-circle" tooltipText="Dimension recommandée 800 x 800px" placement="right" />
          </div>
          <div class="card-body">
            <product-images @formStatusChanged="formStatusChanged" />
          </div>
        </div>

        <variant-drawer
          :open="isVariantFormOpen"
          :options="optionsValues"
          :variant="variantFormData"
          :product-default-price="productDefaultPrice"
          @submit="handleVariantSubmission"
          @cancel="closeVariantForm"
          @delete="handleVariantDeletion"
        />

        <div class="mb-4">
          <save-product-button :edit-mode="editMode" :is-saving="isSaving" @handleSave="handleSave" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { get, pick } from 'lodash-es'
import GeneralForm from '@/components/products/editors/GeneralForm'
import PriceForm from '@/components/products/editors/PriceForm'
import OptionForm from '@/components/products/editors/variable-products/OptionForm'
import VariantTable from '@/components/products/editors/variable-products/VariantTable'
import VariantDrawer from '@/components/products/editors/variable-products/VariantDrawer'
import ProductImages from '@/components/products/editors/ProductImages'
import userData from '@/mixins/user-data'
import formError from '@/mixins/form-error'
import IconTooltip from '@/components/common/IconTooltip'
import SaveProductButton from '@/components/products/editors/SaveProductButton'

export default {
  mixins: [userData, formError],
  components: {
    IconTooltip,
    ProductImages,
    VariantDrawer,
    GeneralForm,
    PriceForm,
    OptionForm,
    VariantTable,
    SaveProductButton
  },
  data () {
    return {
      isVariantFormOpen: false,
      variantFormData: {},
      showErrors: false,
      isGeneralFormValid: null,
      isPriceFormValid: null,
      isReady: false,
      isSaving: false
    }
  },
  computed: {
    editMode () {
      return !!this.$route.params.id
    },
    generalFormValues () {
      return pick(this.$store.state.productEditor, ['name', 'subtitle', 'shortDescription', 'description', 'categoryId'])
    },
    priceFormValues () {
      return {
        price: get(this.$store.state.productEditor, 'defaultPrice'),
        regularPrice: get(this.$store.state.productEditor, 'defaultRegularPrice'),
        taxes: get(this.$store.state.productEditor, 'taxes')
      }
    },
    optionsValues () {
      return this.$store.state.productEditor.options
    },
    variants () {
      return this.$store.state.productEditor.variants
    },
    productDefaultPrice () {
      return this.$store.state.productEditor.defaultPrice
    }
  },
  methods: {
    formStatusChanged (state) {
      if (this.isReady) {
        this.$emit('formStatusChanged', state)
      }
    },
    handleGeneralForm (values, isValid) {
      this.formStatusChanged(JSON.stringify(this.generalFormValues) !== JSON.stringify(values))
      this.$store.commit('productEditor/SET_VALUES', values)
      this.isGeneralFormValid = isValid
    },
    handlePriceForm (values, isValid) {
      this.formStatusChanged(JSON.stringify(this.priceFormValues) !== JSON.stringify(values))
      this.$store.commit('productEditor/SET_VALUES', {
        defaultPrice: Number(values.price),
        defaultRegularPrice: Number(values.regularPrice),
        taxes: values.taxes
      })

      this.isPriceFormValid = isValid
    },
    handleOptions (options) {
      this.formStatusChanged(JSON.stringify(this.optionsValues) !== JSON.stringify(options))
      this.$store.commit('productEditor/SET_VALUES', { options })
    },
    createVariant () {
      this.variantFormData = {}
      this.isVariantFormOpen = true
    },
    editVariant (variant) {
      this.variantFormData = { ...variant }
      this.isVariantFormOpen = true
    },
    handleVariantSubmission (variant, images) {
      this.formStatusChanged(true)

      if (variant.key) {
        this.$store.commit('productEditor/SET_VARIANT_VALUES', variant)
        this.$store.commit('productEditor/UPDATE_PRODUCT_PHOTOS', [...images])
      } else {
        this.$store.commit('productEditor/ADD_VARIANT', { variant, productPhotos: [...images] })
      }

      this.closeVariantForm()
    },
    async handleVariantDeletion (id) {
      try {
        await this.$modal.openConfirmModal({
          title: 'Supprimer la variante',
          message: 'Veuillez confirmer que vous voulez supprimer cette variante.',
          confirmLabel: 'Confirmer',
          cancelLabel: 'Annuler'
        })

        this.$emit('formStatusChanged', true)
        this.$store.commit('productEditor/REMOVE_VARIANT', id)

        if (!this.$store.state.productEditor.productPhotos) {
          return
        }

        // remove deleted variant from product photo and reset to isGlobal = true if needed
        const updatedProductPhotos = this.$store.state.productEditor.productPhotos.map(photo => {
          if (!photo.productVariantPhotos || !photo.productVariantPhotos.length) {
            return { ...photo, isGlobal: true }
          }

          return {
            ...photo,
            isGlobal: photo.productVariantPhotos.length === 0,
            productVariantPhotos: photo.productVariantPhotos.filter(vp => vp.variantId !== id)
          }
        })

        this.$store.commit('productEditor/UPDATE_PRODUCT_PHOTOS', updatedProductPhotos)
        this.closeVariantForm()
      } catch (error) {
        if (error === 'ACTION_CANCELLED') {
          return
        }

        this.$toasted.error('Une erreur s\'est produite. Veuillez réessayer.')
      }
    },
    closeVariantForm () {
      this.isVariantFormOpen = false
      this.variantFormData = {}
    },
    async handleSave (status) {
      this.showErrors = true

      if (!this.isGeneralFormValid || !this.isPriceFormValid) {
        this.toastFormError()
        return
      }

      if (status) {
        this.$store.commit('productEditor/SET_STATUS', status)
      }

      this.isSaving = true
      await this.$store.dispatch('productEditor/save', {
        storeId: this.currentStoreId
      })

      this.$emit('formStatusChanged', false)
      this.isSaving = false

      if (!this.editMode) {
        this.$router.push('/products')
      }
    }
  },
  async created () {
    await Promise.all([
      this.$store.dispatch('taxRates/fetchOrRefresh', this.currentStoreId),
      this.$store.dispatch('variantTypes/fetchOrRefresh', this.currentStoreId)
    ])

    this.isReady = true
  }
}
</script>
