<template>
  <div class="d-flex align-baseline text-nowrap" :class="{'swap-price': swap}">
    <div class="d-flex align-baseline">
      <span v-if="hasDiscount" class="regular-price">
        {{ $money(regularPrice) }}
      </span>
      <div v-if="showDiscount && hasDiscount" class="discount-price">
        <span>{{ discountPercentage }}</span>
      </div>
    </div>
    <span>{{ $money(price) }}</span>
  </div>
</template>

<script>
import formatsCurrency from '@/mixins/formatsCurrency'

export default {
  mixins: [formatsCurrency],
  props: {
    price: {
      type: [Number, String],
      required: true
    },
    regularPrice: {
      type: [Number, String],
      required: false,
      default: null
    },
    showDiscount: {
      type: Boolean,
      required: false,
      default: false
    },
    swap: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    discountPercentage () {
      if (!this.regularPrice) {
        return
      }

      return ((this.price / this.regularPrice) * 100).toFixed(0)
    },
    hasDiscount () {
      return this.regularPrice && this.regularPrice !== this.price
    }
  }
}
</script>

<style lang="scss" scoped>
.swap-price {
  flex-direction: row-reverse;
  justify-content: flex-end;
}
.regular-price {
  text-decoration: line-through;
  margin-right: 0.2rem;
}
.discount-price {
  width: 26px;
  height: 26px;
  line-height: 24px;
  text-align: center;
  margin-left: 0.5rem;
  border: 1px solid #ca1515;
  background: #ca1515;
  border-radius: 50%;
  font-size: 0.5rem;
  color: white;

  > span {
    &::before {
      content: '-';
    }

    &::after {
      content: '%';
    }
  }
}
</style>
