<template>
  <div class="product-image-container">
    <file-upload-button @click="(files) => handleUpload(files, true)" />
      <draggable v-model="productPhotos">
        <transition-group class="d-flex flex-wrap section position-relative" name="fade" tag="div">
          <div
            v-for="image in productPhotos"
            :key="image.fileId"
            class="position-relative mt-3 mr-3 image-pointer"
            @mouseover="showOptions = image.fileId"
            @mouseleave="showOptions = null"
          >
            <div v-if="showOptions === image.fileId" class="image-options">
              <div role="button" class="cursor-pointer" @click="handleImageDelete(image.fileId)">
                <fa-icon :icon="['fas', 'trash']" color="white"></fa-icon>
              </div>
              <div role="button" class="cursor-pointer" @click="selectedImage = image">
                <fa-icon :icon="['fas', 'cog']" color="white"></fa-icon>
              </div>
            </div>
            <file-image :src="getUrl(image.file)" />
            <div class="image-overlay" :class="{'show': showOptions === image.fileId}" />
          </div>
        </transition-group>
    </draggable>
    <image-drawer
      :open="!!selectedImage"
      @cancel="selectedImage = null"
      @delete="handleImageDelete"
      @submit="handleImageUpdate"
      :variants="variants"
      :selected-image="selectedImage"
      :product-type="productType"
      :product-default-price="productDefaultPrice"
      :product-default-regular-price="productDefaultRegularPrice"
    />
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import { cloneDeep, orderBy } from 'lodash-es'
import productImages from '@/mixins/productPhotos'
import FileImage from '@/components/common/FileImage'
import FileUploadButton from '@/components/common/FileUploadButton'
import ImageDrawer from '@/components/products/editors/ImageDrawer'

export default {
  mixins: [productImages],
  components: { draggable, ImageDrawer, FileImage, FileUploadButton },
  data () {
    return {
      showOptions: null,
      selectedImage: null,
      isReady: false
    }
  },
  computed: {
    variants () {
      return orderBy(this.$store.state.productEditor.variants, ['options'], 'asc')
    },
    productType () {
      return this.$store.state.productEditor.productType
    },
    productDefaultRegularPrice () {
      return this.$store.state.productEditor.defaultRegularPrice
    },
    productDefaultPrice () {
      return this.$store.state.productEditor.defaultPrice
    }
  },
  methods: {
    formStatusChanged (state) {
      if (this.isReady) {
        this.$emit('formStatusChanged', state)
      }
    },
    async handleImageDelete (fileId) {
      try {
        await this.$modal.openConfirmModal({
          title: "Supprimer l'image",
          message: 'Veuillez confirmer que vous voulez supprimer cette image.',
          confirmLabel: 'Confirmer',
          cancelLabel: 'Annuler'
        })
      } catch {
        return
      }

      const filteredProductPhotos = cloneDeep(this.productPhotos)
        .filter((image) => image.file.id !== fileId)
        .map((photo, index) => ({ ...photo, rank: index + 1 }))

      this.$emit('formStatusChanged', true)
      this.$store.commit('productEditor/UPDATE_PRODUCT_PHOTOS', filteredProductPhotos)
      this.selectedImage = null
    },
    handleImageUpdate (image) {
      const photos = this.productPhotos.map(photo => {
        if (this.selectedImage.fileId === photo.fileId) {
          return { ...image }
        }

        return { ...photo }
      })

      this.$emit('formStatusChanged', true)
      this.$store.commit('productEditor/UPDATE_PRODUCT_PHOTOS', photos)
      this.selectedImage = null
    }
  },
  created () {
    this.isReady = true
  }
}
</script>

<style lang="scss" scoped>
  .product-image-container {
    box-sizing: border-box;
    position: relative;

    > .section {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      align-items: center;
    }
  }
  .image-options {
    cursor: pointer !important;
    position: absolute;
    top: 0.8rem;
    padding: 0 0.8rem;
    z-index: 2;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .image-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    background: black;
    visibility: hidden;
    opacity: 0;
    &.show {
      transition: opacity .30s, visibility .30s;
      visibility: visible;
      opacity: 0.3;
    }
  }

  .fade-leave-active {
    display: none
  }

  .image-pointer {
    cursor: grab;

    &:active {
      cursor: grabbing;
    }
  }
</style>
