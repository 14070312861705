<template>
  <div class="mb-4">
    <div class="row">
      <div v-if="!editMode" class="col-12">
        <div class="form-inline">
          <div class="form-group">
            <button-loader :disabled="isSaving" :loading="isSaving" @click="handleSave('published')">
              {{ labelSaveAsPublished }}
            </button-loader>
            <div class="ml-2 hidden-md"></div>
            <div class="mt-2 block-md"></div>
            <button-loader variant="outline-primary" :disabled="isSaving" :loading="isSaving" @click="handleSave()">
              {{ labelSaveAsDraft }}
            </button-loader>
          </div>
        </div>
      </div>
      <div v-if="editMode" class="col-12">
        <div class="row">
          <div class="col-12">
            <div class="form-inline">
              <div class="form-group">
                <span>{{ labelSaveAs }}</span>
                <div class="ml-2 hidden-md"></div>
                <div class="mt-2 block-md"></div>
                <select class="custom-select" v-model="status">
                  <option v-for="(item, key) in listStatus" :key="key" :value="item.value">{{ item.label }}</option>
                </select>
              </div>
            </div>
          </div>
          <div class="col-12 mt-2">
            <div class="form-inline">
              <div class="form-group">
                <button-loader :disabled="isSaving" :loading="isSaving" @click="handleSave()">
                  {{ labelSave }}
                </button-loader>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ButtonLoader from '@/components/common/ButtonLoader'

export default {
  components: {
    ButtonLoader
  },
  props: {
    labelSaveAs: {
      type: String,
      default: 'Statut de publication :'
    },
    labelSaveAsDraft: {
      type: String,
      default: 'Enregistrer comme brouillon'
    },
    labelSaveAsPublished: {
      type: String,
      default: 'Enregistrer et publier'
    },
    labelSave: {
      type: String,
      default: 'Enregistrer'
    },
    listStatus: {
      type: Array,
      default: () => [
        {
          value: 'draft',
          label: 'Brouillon'
        },
        {
          value: 'published',
          label: 'Publié'
        },
        {
          value: 'archived',
          label: 'Archivé'
        }
      ]
    },
    isSaving: {
      type: Boolean,
      default: false
    },
    editMode: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    status: {
      get () {
        return this.$store.state.productEditor.status
      },
      set (value) {
        this.$store.commit('productEditor/SET_STATUS', value)
      }
    }
  },
  methods: {
    handleSave (status) {
      this.$emit('handleSave', status)
    }
  }
}
</script>
