<template>
  <div>
    <v-dynamic-form
      :fields="fields"
      :initial-values="initialValues"
      @change="handleChange"
    ></v-dynamic-form>
  </div>
</template>

<script>
import handleForm from '@/mixins/handle-form'

export default {
  mixins: [handleForm],
  computed: {
    fields () {
      return [
        {
          name: 'length',
          type: 'number',
          label: 'Longueur',
          labelSecondary: '(en cm)'
        },
        {
          name: 'width',
          type: 'number',
          label: 'Largeur',
          labelSecondary: '(en cm)'
        },
        {
          name: 'height',
          type: 'number',
          label: 'Hauteur',
          labelSecondary: '(en cm)'
        },
        {
          name: 'weight',
          type: 'number',
          label: 'Poids',
          labelSecondary: '(en Kg)'
        }
      ]
    },
    validationRules () {
      return {}
    }
  }
}
</script>

<style scoped>
</style>
