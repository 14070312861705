<template>
  <app-layout>
    <div class="px-4 px-sm-5 container container-left">
      <div class="mb-4">
        <router-link class="" to="/products" active-class="active">
          <fa-icon :icon="['fas', 'chevron-left']" size="sm"></fa-icon>&nbsp;Retour
        </router-link>
      </div>

      <div class="heading d-flex justify-content-between mb-5">
        <h2>Importer vos produits</h2>
      </div>

      <b-tabs v-model="tabIndex" content-class="w-100">
        <b-tab title="Import" class="py-4">
          <import-products @switch="handleShowDocumentation"></import-products>
        </b-tab>
        <b-tab title="Documentation" class="py-4">
          <import-products-documentation />
        </b-tab>
      </b-tabs>
    </div>
  </app-layout>
</template>

<script>
import ImportProducts from '@/components/products/ImportProducts'
import ImportProductsDocumentation from '@/components/products/csv-import/ImportProductsDocumentation'

export default {
  components: {
    ImportProductsDocumentation,
    ImportProducts
  },
  data () {
    return {
      tabIndex: 0
    }
  },
  methods: {
    handleShowDocumentation () {
      this.tabIndex = 1
    }
  },
  watch: {
    '$store.getters.isSuspended': {
      handler (value) {
        if (value) {
          this.$router.push('/')
        }
      },
      deep: true
    }
  }
}
</script>
